import React from 'react';
import PageIntro from '../PageIntro';

function CHUB() {
    return (
        <main>
            <PageIntro
                title="C-HUB"
                subtitle="Maak je ISMS of security programma meetbaar en operationeel"
                breadcrumbs={[
                    { href: "/diensten", title: "Diensten" },
                    { href: "/c-hub", title: "C-HUB" }
                ]}
            />
            <div className="container">
            <div className="grid md:grid-cols-2 gap-8 pb-8">
                <div className="min-h-60 pt-8">
                    <h1 className="font-medium text-blue-500 text-xl md:text-2xl">Waarom we C-HUB ontwikkeld hebben</h1>
                    <p className="text-gray-600 text-lg">
                        We zien bij veel organisaties dezelfde uitdagingen:
                        <ul className="list-disc list-inside pt-4">
                            <li className="text-gray-600">
                                Wat doen we eigenlijk na een implementatie van een security programma / ISMS? Er is werk ontstaan, we moeten nu de activiteiten die we bedacht hebben ook doen, controleren, evalueren en beoordelen.
                            </li>
                            <li className="text-gray-600 pt-4">
                                Wie gaat dat doen? Bestaande medewerkers opleiden? Of toch en vacature openzetten? Goede security officers zijn schaars. Hoe weet ik eigenlijk wat ik nodig heb van mijn security team? Kan ik hen aansturen?
                            </li>
                            <li className="text-gray-600 pt-4 pb-4">
                                En hoe geven we eigenlijk invulling aan de management verantwoordelijkheden?
                            </li>
                        </ul>
                    Niet alle werkzaamheden van de security officer vereisen het niveau van een senior strategische chief information security. Door een divers team met verschillende niveaus bij jouw organisatie een C-HUB programma uit te laten voeren kunnen we specialiseren en werkt C-HUB meer efficiënt.
                    </p>
                </div>
                <div className="pt-8">
                    <h1 className="font-medium text-blue-500 text-xl md:text-2xl md:text-right">Een onafhankelijk, constant tempo</h1>
                    <div className="grid md:grid-cols-3 gap-4">
                        <div className="md:col-span-1 hidden md:block">&nbsp;</div>
                        <div className="md:col-span-2">
                            <p className="text-lg md:text-right text-gray-600 leading-relaxed">
                                Ritme in je security programma houden is een uitdaging. Een van de voordelen van C-HUB is dat ons ritme onafhankelijk is van dat in jouw organisatie.
                                Niet meer: "we hebben de belangrijke dingen even uitgesteld omdat er urgentere dingen te doen waren."
                            </p>
                            <p className="text-lg pt-4 md:text-right text-gray-600 leading-relaxed">
                                We meten steeds: doen we in jullie organisatie alles nog, voldoende, en op tijd? Je bereikt een goede security posture door te doen wat je op hebt geschreven, op te schrijven wat je doet, er kritisch op te reflecteren, te zorgen dat je mensen het weten en snappen en vooral ook door <strong>er niet meer mee te stoppen</strong>.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <section className="bg-ocean-50">
                <div className="container">
                        <div className="grid">
                            <div className="grid md:grid-cols-[400px,1fr] gap-20 md:py-10 py-5">
                                <div>
                                    <img
                                        src="images/doen-begeleiden-controleren.svg"
                                        alt="Doen, begeleiden en controleren diagram"
                                        className="w-[350px] h-auto"
                                    />
                                </div>
                                <div>
                                    <h1 className="text-xl md:text-2xl text-blue-500 font-medium mb-8 md:text-right">
                                    De juiste samenstelling van ontzorgen en zelfstandig
                                    </h1>
                                    <p className="text-lg md:text-right leading-relaxed text-gray-600">
                                    Voor elk onderdeel kun je kiezen: doe je dit zelf en controleren wij? Wil je begeleiding (naar uiteindelijk zelf doen met onze controle?) of wil je helemaal ontzorgd worden en dat wij alles doen?
                                    </p>
                                    <p className="text-lg md:text-right leading-relaxed pt-4 text-gray-600">
                                    Wat je altijd zelf moet doen is besluiten maken. Wij zorgen voor de juiste informatie en gedegen advies. De rol van security management is niet de organisatie overnemen, of dingen opleggen, maar het management transparant confronteren wat de risico-gevolgen van keuzes zijn.
                                    </p>
                                </div>
                            </div>
                        </div>
                </div>
            </section>
            <section className="mt-8 md:mt-12">
                <div className="container">
                <div className="grid md:grid-cols-2 gap-8 pb-8">
                    <div className="pt-8">
                        <div className="md:w-3/4">
                            <h1 className="font-medium text-gray-900 text-xl md:text-2xl">Onderwerpen meetbaar en operationeel met periodieke controles, tests en reviews</h1>
                        </div>
                        <ul className="list-disc list-inside">
                            <li className="text-gray-600">Logical access</li>
                            <li className="text-gray-600">Authorization and priviledged access</li>
                            <li className="text-gray-600">Asset management</li>
                            <li className="text-gray-600">Business continuity</li>
                            <li className="text-gray-600">Cryptography</li>
                            <li className="text-gray-600">HR leavers joiners</li>
                            <li className="text-gray-600">Awareness</li>
                            <li className="text-gray-600">Incident management</li>
                            <li className="text-gray-600">Mobile devices</li>
                            <li className="text-gray-600">Network security</li>
                            <li className="text-gray-600">Physical security</li>
                            <li className="text-gray-600">Supplier management</li>
                            <li className="text-gray-600">Threat intelligence</li>
                            <li className="text-gray-600">Vulnerability patching</li>
                            <li className="text-gray-600">Secure coding</li>
                            <li className="text-gray-600">...</li>
                        </ul>
                    </div>
                    <div className="pt-8">
                        <h1 className="font-medium text-grey-900 md:text-2xl text-xl">
                            De juiste metingen en rapportage waardoor <em>jij</em> strategische beslissingen kunt nemen
                        </h1>
                        <p className="text-gray-600  md:pt-4 pt-2 text-lg">
                        Strakke KPI's gericht op het meten of we op alle voor jouw organisatie belangrijke onderwerpen doen wat je hebt opgeschreven, wat nodig is. Bij het niet halen van een doelstelling kijken we samen hoe dit komt en of we er iets aan moeten doen.
                        </p>
                        <p className="text-gray-600  md:pt-4 pt-2 text-lg">
                        Maandelijkse rapportage, met een dashboard waarin je kunt zien wat er gebeurt, wat we gemeten hebben en wat we adviseren.
                        </p>
                        <p className="text-gray-600  md:pt-4 pt-2 text-lg">
                        Risk management feedback loop: periodieke risico-analyse en -beoordeling met risico-eigenaren: we kijken naar acuuraatheid (van inschatting grootte), effectiviteit, efficiency en compleetheid.
                        </p>
                        <p className="text-gray-600  md:pt-4 pt-2 text-lg">
                        Periodieke management reviews met door ons voorbereide input over je security posture waar je op kunt besluiten.
                        </p>
                    </div>
                </div>
                </div>
            </section>
            <section className='mt-8 md:mt-12'>
                <div className="container">
                    <h1 className="text-2xl md:text-3xl text-blue-500 font-medium mb-8">
                        Awareness met nano learning
                    </h1>
                    <p className="text-gray-600 text-lg mb-8 max-w-3xl">
                        Met korte, interactieve leermomenten houden we je medewerkers betrokken en alert op het gebied van informatiebeveiliging. Deze nano learning aanpak zorgt voor effectieve kennisoverdracht zonder grote tijdsinvestering, waardoor security awareness natuurlijk wordt geïntegreerd in de dagelijkse werkzaamheden.
                    </p>
                    <h2 className="text-lg font-medium mb-8 max-w-3xl">We zijn hierover zo enthousiast dat we samenwerken met onze software partner JungleMap en dit altijd meeleveren in onze C-HUBs.</h2>
                </div>
            </section>
            <section className="bg-ocean-50 py-12 md:py-20 mt-12 md:mt-20">
                <div className="container">
                    <h1 className="text-3xl md:text-5xl text-center text-ocean-600 font-bold mb-12 md:mb-20">
                        Stel C-HUB samen voor jouw organisatie
                    </h1>
                    <div className="max-w-7xl">
                        <dl className="grid grid-cols-1 gap-x-8 gap-y-16 lg:grid-cols-3">
                            <div className="flex flex-col gap-y-4 lg:max-w-xs">
                                <dt className="text-base/7 text-gray-600">
                                Bij de intake kiezen we samen welke onderdelen op welk ontzorgingsniveau worden geleverd.
                                </dt>
                                <dd className="order-first md:text-3xl font-medium tracking-tight text-gray-900 text-xl">Altijd passend</dd>
                            </div>
                            <div className="flex flex-col gap-y-4 lg:max-w-xs">
                                <dt className="text-base/7 text-gray-600">
                                Een vaste prijs op basis van aantal medewerkers, systemen en organisatie-complexiteit. <br></br><br></br>Voor kosten vergelijkbaar met een interne medewerker, krijg je meer expertise en betrouwbaardere output.
                                </dt>
                                <dd className="order-first md:text-3xl font-medium tracking-tight text-gray-900 text-xl">Voorspelbare kosten</dd>
                            </div>
                            <div className="flex flex-col gap-y-4 lg:max-w-xs">
                                <dt className="text-base/7 text-gray-600">
                                We maken precieze afspraken over wat we doen, meten en waarover we rapporteren. We adviseren ongevraagd en gevraagd hoe je onze output in besluiten kunt omzetten.
                                </dt>
                                <dd className="order-first md:text-3xl font-medium tracking-tight text-gray-900 text-xl">Fixed output + advies</dd>
                            </div>
                        </dl>
                    </div>
                    <div className='pt-8'>
                        <a href="/contact" className="py-3 sm:mt-4  px-6 inline-block font-bold rounded hover:shadow text-white bg-ocean-600 hover:bg-ocean-700">Plan een intake gesprek</a>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default CHUB;