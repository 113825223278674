
import React from 'react';
import { FaShieldAlt, FaUsers, FaChalkboardTeacher, FaClipboardCheck, FaBalanceScale, FaUserShield, FaUserCog } from 'react-icons/fa';


export const services = [
    {
        title: "ISO 27001 implementatie projecten",
        description: "Niet alleen voldoen aan de norm, maar een blauwdruk om jouw organisatie naar een volgend niveau te tillen. <br><br> Ook: ISO9001, NEN7510, PSD2, TPN",
        icon: <FaShieldAlt className="text-4xl text-gray-600 mb-4" />,
        ctaText: "Certificering behalen",
        ctaLink: "/diensten/iso-implementatie"
    },
    {
        title: "Het security programma van je organisatie meetbaar en voorspelbaar uitbesteden",
        description: "Bij het onderhouden en draaien van je security programma komt veel kennis kijken. <br><br> C-Hub is er voor organisaties die ontzorgd willen worden.",
        icon: <FaUsers className="text-4xl text-gray-600 mb-4" />,
        bgColor: 'bg-ocean-50',
        ctaText: "Ontdek C-HUB",
        ctaLink: "/c-hub",
        footerTitle: "C-HUB"
    },
    {
        title: "Workshops",
        description: "Over security, privacy, compliance en vooral governance: we zien vaak dat het management systeem stuk van het ISMS het moeilijkst is. <br><br>Altijd toegespitst op jouw unieke organisatie.",
        icon: <FaChalkboardTeacher className="text-4xl text-gray-600 mb-4" />,
        ctaText: "Plan een workshop",
        ctaLink: "/diensten/workshops"
    },
    {
        title: "Quickscan NIS2",
        description: "Samen kijken we waar je organisatie staat middels zelfevaluatie en een assessment.",
        icon: <FaUserCog className="text-4xl text-gray-600 mb-4" />,
        ctaText: "Quickscan NIS2",
        ctaLink: "/diensten/quickscan-nis2"
    },
    {
        title: "BIO / NIS2 implementatie",
        description: "Sta je voor de uitdaging om NIS2 of BIO te implementeren? Of om eraan te blijven voldoen? <br/><br/>Triple Control staat klaar om je organisatie hierin te ondersteunen.",
        icon: <FaClipboardCheck className="text-4xl text-gray-600 mb-4" />,
        ctaText: "Werken aan een veilige overheid",
        ctaLink: "/diensten/bio-nis2"
    },
    {
        title: "Compliance, risk, governance advies",
        description: "We kunnen je helpen met concrete vraagstukken, zoals het ontwikkelen van een security programma voor jouw organisatie of een awareness campagne.",
        icon: <FaBalanceScale className="text-4xl text-gray-600 mb-4" />,
        ctaText: "Hoe onze adviseurs je kunnen helpen",
        ctaLink: "/diensten/compliance-risk-governance",
        footerTitle: "GRC advies"
    },
    // {
    //     title: "Security/compliance officer",
    //     description: "Bij (tijdelijke) invulling van deze rol kunnen we je helpen.",
    //     icon: <FaUserShield className="text-4xl text-gray-600 mb-4" />,
    //     ctaText: "Triple Control officers",
    //     ctaLink: "/diensten/security-compliance-officer"
    // },

];

export const ServicesComponent = () => {

    return (
        <section className="home-content-blocks pb-12 md:pb-20">
            <div className="container">
                <div className="grid gap-6 md:grid-cols-3">
                    {services.map((service, index) => (
                        <div key={index} className={`flex flex-col text-left relative ${service.bgColor || 'bg-gray-50'} rounded-xl p-6 shadow-md`}>
                            {service.icon}
                            <h2 className="text-blue-500 font-medium text-lg md:text-2xl relative z-10 mb-2">
                                <div dangerouslySetInnerHTML={{ __html: service.title }} />
                            </h2>
                            <p className='leading-6 md:leading-7 md:text-lg text-gray-600 relative z-10 mb-4'>
                                <div dangerouslySetInnerHTML={{ __html: service.description }} />
                            </p>
                            <a
                                href={service.ctaLink}
                                className="mt-auto bg-ocean-600 font-medium text-white py-2 px-4 rounded hover:bg-ocean-700 transition duration-300 md:text-center text-left w-2/3 md:w-full"
                            >
                                {service.ctaText}
                            </a>
                        </div>
                    ))}
                </div>
            <div className="mt-12 text-left md:text-center">
                <p className="text-gray-600 text-xl md:text-2xl">
                    (Tijdelijke) <a href="/diensten/security-compliance-officer" className='text-blue-500 font-medium'>security officer</a> of compliance officer nodig?
                    Ook dan kunnen we <a href="/diensten/security-compliance-officer" className='text-blue-500 font-medium'>helpen</a> bij de invulling van deze rol.
                </p>
            </div>
            </div>
        </section>
    );
}

export default ServicesComponent;
