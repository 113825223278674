import React from 'react';
import PageIntro from '../PageIntro';
import QuoteQuestionBlock from '../home/QuoteQuestionBlock';
function SecurityPrivacyOfficer() {
    return (
        <main className="flex-grow">
            <PageIntro
                title="Security / compliance officer"
                subtitle="Expertise voor kortere of langere tijd"
                breadcrumbs={[
                    { href: "/diensten", title: "Diensten" },
                    { href: "/diensten/security-compliance-officer", title: "Security / compliance officer" }
                ]}
            />
        <div className="container">
        <div className="mx-auto max-w-3xl lg:mx-0 pb-8 md:pb-12">



          <p className="leading-6 md:leading-9 md:text-lg text-gray-600 pb-4 pt-8">
          Wij begrijpen dat informatiebeveiliging niet de core business van jouw organisatie is. Maar wel dat het de core van je organisatie raakt. Je wilt je data, persoonsgegevens en informatie beschermen: Je wilt aantoonbaar beschermd zijn tegen datalekken, maar eigenlijk gaat het verder: je wilt goede managementbeslissingen nemen, je team ontwikkelen en risico's slim managen. Onze mensen helpen daarbij,
          altijd vanuit de overtuiging dat innovatie en compliance niet elkaars vijanden zijn en compliance lekker kan werken voor iedereen. Dat noemen we: <em>keep the fun, work securily</em>.
          </p>
          <p className="md:text-lg leading-6 md:leading-9 text-gray-600 py-4">
          Misschien heb je tijdelijk extra handen nodig om een specifiek probleem op te lossen, of zoek je een structurele oplossing met een onafhankelijke expert die snel jouw organisatie doorgrondt. Bij Triple Control krijg je niet alleen kwaliteit, maar ook de juiste mentaliteit.
          </p>
          <div className="mt-8">
            <a href="/over-ons" className="py-3 px-6 inline-block font-bold rounded hover:shadow text-white bg-ocean-600 hover:bg-ocean-700">
              Meer over de kernwaarden
            </a>
          </div>
          <h3 className="text-xl md:text-2xl font-medium text-grey-600 py-8 text-blue-500">Onze mensen</h3>
          <p className="leading-6 md:leading-9 md:text-lg text-gray-600 pb-4 pl-4">
          <i class="fa-solid fa-arrow-right-long"></i> <span className="pl-4">We leggen veel nadruk op het organiseren van ownership. Ook daar maken we door de structuur het goede makkelijker.</span>
          </p>
          <p className="leading-6 md:leading-9 md:text-lg text-gray-600 pb-4 pl-4">
          <i class="fa-solid fa-arrow-right-long"></i> <span className="pl-4">Werken binnen de TripleC structuur , die jouw risk owners in staat stelt te oordelen over effectiviteit completeness, efficiency en veracity.</span>
          </p>
          <p className="leading-6 md:leading-9 md:text-lg text-gray-600 pb-4 pl-4">
          <i class="fa-solid fa-arrow-right-long"></i> <span className="pl-4">We maken het niet ingewikkelder dan het is. Door ownership scherp te maken krijg je accountable professionals die dicht bij het vuur zitten. Geen ivoren toren risicomanagement, maar korte effectieve verbetercycli die breed gedragen worden.</span>
          </p>
          <p className="md:text-lg leading-6 md:leading-9 text-gray-600 pb-4">
          En dat kunnen ze gelukkig in allerlei rollen, of je nu op zoekt bent naar een (C)ISO, Risk manager, Programma manager, Privacy Champion of Security Architect. Onze mensen staan er niet alleen voor en worden ondersteund door andere experts uit ons security en privacy team.
          </p>

          <div className="rounded-xl bg-ocean-50 p-8 mt-12">
          <h3 className="text-xl md:text-2xl font-medium text-grey-600 text-blue-500">C-HUB</h3>
          <p>Kleine en middelgrote organisaties kiezen vaak voor ons C-Hub, waarin je alle ondersteuning onder een dak hebt. Flexibel en voor een vaste prijs.</p>
          <div className="mt-4">
            <a href="/c-hub" className="py-1 px-4 inline-block font-medium rounded hover:shadow text-white bg-ocean-600 hover:bg-ocean-700 transition duration-300">
            Solide risk management zonder bureaucratie met C-Hub
            </a>
          </div>
          </div>
        </div>
      </div>
         <QuoteQuestionBlock
            title="Zijn dit lastige vragen?"
            questions={[
                "Weet jij hoe je waardevolle datastromen lopen?",
                "Hoe werken de processen en systemen?",
                "Is er een compleet, actueel overzicht van alle bewegende onderdelen?",
            ]}
            quote={'"Beter hoofdpijn vooraf dan buikpijn achteraf"'}
            author="Bas de Kwant"
            authorImage="/images/photo-bas-de-kwant.jpg"
            bg="white"
         />
        </main>
    );
}

export default SecurityPrivacyOfficer;