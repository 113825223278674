import React from 'react';
import PageIntro from '../PageIntro';
import QuoteQuestionBlock from '../home/QuoteQuestionBlock';
function Advies() {
    return (
        <main>
            <PageIntro
                title="Quickscan / nul-meting NIS2"
                subtitle="Is je organisatie klaar voor NIS2?"
                breadcrumbs={[
                    { href: "/diensten", title: "Diensten" },
                    { href: "/diensten/quick-scan", title: "Quickscan / nul-meting NIS2" }
                ]}
            />
        <div className="container">
            <div className="mx-auto max-w-3xl lg:mx-0 pb-8 md:pb-12">
                <p className="leading-6 md:leading-9 md:text-lg text-gray-600 pb-4 md:pt-12 pt-8">
                Uit ons <a href="/onderzoek" className="text-forest-500 hover:text-forest-600 font-medium"> onderzoek</a> blijkt dat 68,1% van de decentrale overheidsorganisaties al bezig is met de voorbereidingen of een implementatieplan heeft voor NIS2. Toch heeft 14,9% nog geen actie ondernomen, en 17,0% is zich niet bewust van de NIS2-verplichtingen. Waar je ook staat, Triple Control staat klaar om je organisatie te ondersteunen.
                </p>
                <h3 className="text-2xl font-medium text-blue-500 pb-4 pt-8">
                Speciaal voor gemeenten en ketenpartners van gemeenten
                </h3>
                <p className="leading-6 md:leading-9 md:text-lg text-gray-600 pb-4">
                Samen kijken we waar je organisatie staat middels zelfevaluatie en een assessment. Bij een zelfevaluatie beoordelen je eigen medewerkers in een workshop of interview in hoeverre aan de maatregelen wordt voldaan. Tijdens een assessment vormen wij een oordeel op basis van gesprekken, workshops en documentatie.
                </p>
                <p className="leading-6 md:leading-9 md:text-lg text-gray-600 pb-4">
                In beide gevallen kijken we naar de implementatie van maatregelen én de positionering binnen de organisatie. Zijn zaken alleen 'op papier' goed geregeld, of ook in de praktijk? En is dit aantoonbaar? In het beleid ook praktisch; maken we het goede doen makkelijk en het verkeerde doen moeilijk genoeg?
                </p>
                <p className="leading-6 md:leading-9 md:text-lg text-gray-600 pb-4">
                Met een GAP-analyse bepalen we samen wat de organisatie nodig heeft en waar de optimalisatiemogelijkheden liggen.
                </p>

                <h3 className="text-2xl font-medium text-blue-500 pb-4 pt-8">Concreet weten wat je kunt doen</h3>
                <p className="leading-6 md:leading-9 md:text-lg text-gray-600 pb-4">
                Op basis van het assessment, de policy review en de GAP analyse stellen we in overleg een duidelijk plan van aanpak op. Dit omvat het opstellen of aanpassen van beleid, het implementeren van maatregelen en het verder verankeren van informatiebeveiliging binnen de organisatie volgens de PDCA-cyclus. Waar nodig maken we keuzes tussen zelf doen en uitbesteden. En ook in het vervolgtraject kunnen we je natuurlijk helpen middels <a href="/diensten/implementatie" className="text-forest-500 hover:text-forest-600 font-medium">implementatie</a>, <a href="/c-hub" className="text-forest-500 hover:text-forest-600 font-medium">borging</a> of <a href="/diensten/compliance-risk-governance" className="text-forest-500 hover:text-forest-600 font-medium">ondersteuning</a>.
                </p>
                <h3 className="text-2xl font-medium text-blue-500 pb-4 pt-8">Waarom Triple Control?</h3>
                <ul className="pl-4">
                    <li className="leading-6 md:leading-9 md:text-lg text-gray-600 pb-4">
                        <i className="fa-solid fa-arrow-right-long"></i> <span className="pl-4">We zijn niet bang om te oordelen en geloven in de kracht van je eigen experts. We maken het verschil in de organisatie door écht iets te brengen, te inspireren, zodat je met onze hulp groeit, in plaats van checklists afwerkt.
                        </span>
                    </li>
                    <li className="leading-6 md:leading-9 md:text-lg text-gray-600 pb-4">
                        <i className="fa-solid fa-arrow-right-long"></i> <span className="pl-4">We begrijpen dat je organisatie niet om security draait, maar om mensen, inwoners en de vele diensten die je aanbiedt. Daarom zorgen ervoor dat elke euro die je investeert in risicomanagement en informatiebeveiliging optimaal rendeert.
                        </span>
                    </li>
                    <li className="leading-6 md:leading-9 md:text-lg text-gray-600 pb-4">
                        <i className="fa-solid fa-arrow-right-long"></i> <span className="pl-4">Met onze no-nonsense mentaliteit en transparante aanpak maken we informatiebeveiliging begrijpelijk en werkbaar voor iedereen in de organisatie.
                        </span>
                    </li>
                </ul>
                <h3 className="text-2xl font-medium text-blue-500 pb-4 pt-8">Neen contact op</h3>
                <p className="leading-6 md:leading-9 md:text-lg text-gray-600 pb-4">
                Wil je weten hoe jouw organisatie ervoor staat en welke stappen nodig zijn om compliant te worden met NIS2? Neem contact met ons op voor onze efficiënte quick scan.
                </p>
                <a href="/contact" className="py-3 sm:mt-4  px-6 inline-block font-bold rounded hover:shadow text-white bg-ocean-600 hover:bg-ocean-700">
              Vraag ons naar de quickscan!
            </a>
            </div>

        </div>
        <QuoteQuestionBlock title="De fundamentele vraag die je moet stellen als iets mis is gegaan:"  quote={'Was het goede doen, het gewenste gedrag, hier door het beleid makkelijk genoeg gemaakt? Of was wat we hadden gewild een complexe omweg en is dat niet het probleem?'} author="Maurice Hartsinck" authorImageUrl="/images/photo-maurice-hartsinck.jpg" bg="ocean" ctaText='Praat erover verder met ons' />
        </main>
    );
}

export default Advies;